import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Carousel,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

import LuteinComplexMobile from "./../images/Hepatofelin-mobile.jpg"
import LuteinComplexTablet from "./../images/Hepatofelin-tablet.jpg"
import LuteinComplexDesktop from "./../images/Hepatofelin-desktop.jpg"
import LuteinComplexXL from "./../images/Hepatofelin-desktop-xl.jpg"
import Feature1 from "./../images/hep-liver-img.jpg"
import Feature2 from "./../images/hep-milk-thistle.jpg"
import Feature3 from "./../images/hep-liver-prevention.jpg"
import Feature4 from "./../images/hep-artichoke.jpg"
import Feature5 from "./../images/omega-3-acids.jpg"
import FeatureLicaps from "./../images/LICAPS-GUARANTEE.jpg"
import Testimonial1 from "./../images/anastasia-atanasova.jpg"
import Testimonial2 from "./../images/angelina-ivanova.jpg"
import Testimonial3 from "./../images/kolio-zdravkov.jpg"

const HepatofelinPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "Hepatofelin" }}>
    <Seo title="Hepatofelin - грижа за черния дроб" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <Breadcrumb.Item active>HEPATOFELIN</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Hepatofelin"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide">
            Hepatofelin
          </h5>
          <h1 className="display-4 font-light">
            Оптимална подкрепа за
            <br /> черния дроб
          </h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">Hepatofelin</h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Оптимална подкрепа
            <br />
            за черния дроб
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Течна формула за бързо усвояване на най-полезните за черния дроб
            вещества – силимарин, екстракти от артишок и глухарче, подпомагани
            от ленено и соево масло.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/bg/vh4-hepatofelin"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Купете онлайн от <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Продава се в аптеките в цялата страна</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>без разтворители</li>
                <li>без консерванти</li>
                <li>без глутен</li>
                <li>без захар</li>
                <li>без ГМО</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Черният дроб – „химичната лаборатория“ на организма
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Черният дроб – „химичната лаборатория“ на организма"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Хепатофелин е натурален продукт подкрепящ функциите на черния дроб,
            който съдържа оптимална доза екстракт от бял трън (силимарин),
            артишок, глухарче, соево и ленено масло.
          </p>
          <p>
            В черния дроб се обработват и разграждат веществата, които приемаме.
            Злоупотребата с алкохол, тютюнопушенето, лекарствата, както и някои
            вируси и паразити, могат да предизвикат увреждания на черния дроб.
            Билковата терапия често е решение на голяма част от наблюдаваните
            проблеми, също е препоръчителна за профилактика.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Пречистване на организма от токсините</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature2}
            alt="Пречистване на организма от токсините"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Някои вещества и медикаменти, като парацетамол и ацетоминофен,
            натоварват черния дроб и не трябва да се прекалява с употребата им.
          </p>
          <p>
            Силимарина (екстракта от бял трън) е основната съставка на
            Хепатофелин и е един от най-ефективните природни продукти, за
            подобряване функцията на черния дроб и поддържане дейността на
            чернодробните клетки. Ефективността му е подкрепена от различни
            изследвания и той се използва от векове при различни чернодробни
            състояния.
          </p>
          <p>
            Артишокът е особено богат на цинарин - мощен антиоксидант, подкрепящ
            детоксикиращите функции на черния дроб и на отделителната система.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Профилактика и подкрепа</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature3}
            alt="Профилактика и подкрепа"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Активните съставки в Хепатофелин подкрепят чернодробната функция и
            хепатоцитите, помагат за противодействане на нежеланите ефекти при
            употребата на медикаменти, злоупотреба с алкохол и цигари. Те
            подкрепят нормализирането на нивата на чернодробните ензими. Налични
            данни сочат, че приемът на Хепатофелин увеличава с 23%
            регенеративните възможности на чернодробните клетки, след като в
            организма са се натрупали "вредители", вследствие употребата на
            алкохол, никотин, медикаменти и пр.
          </p>
          <p>
            Ефективността на веществата в Хепатофелин е подкрепена и от това, че
            те са в предварително разтворено състояние, което гарантира бързото
            им усвояване от организма.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Засилен метаболизъм и подобрено храносмилане
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Засилен метаболизъм и подобрено храносмилане"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Действието на активните съставки върху черния дроб и жлъчката, се
            отразява на метаболизма и подкрепя храносмилането.
          </p>
          <p>
            Артишокът засилва жлъчната секреция и диурезата, и в същото време
            стимулира метаболизма на холестерола. Глухарчето съдържа биоактивни
            вещества, които подпомагат секрецията и тонуса на храносмилателната
            система. То подкрепя дейността на черния дроб, диуретичната функция
            на бъбреците и пикочните пътища, както и на стомашно-чревния тракт.
          </p>
          <p>
            Хепатофелин съдържа и соево и ленено масло, имащи благоприятни
            ефекти върху метаболизирането на мазнините в кръвта, регулацията на
            серумния холестерол и подкрепа на сърдечно-съдовата система.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Обогатен с Омега-3 мастни киселини за здрав организъм
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature5}
            alt="Обогатен с Омега-3 мастни киселини за здрав организъм"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Хепатофелин съдържа и Омега-3 мастни киселини под формата на ленено
            масло. Те са необходими за нормалното функциониране на клетката и
            спомагат за предпазване на тялото от развитие на редица заболявания.
            Също така подкрепят сърдечно-съдовата система и спомагат за
            поддържане нивата на холестерола в здравословни норми. Омега-3
            мастните киселини са жизненоважен компонент, който човешкият
            организъм не може сам да произведе.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Гаранция за качество и сигурност</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={FeatureLicaps}
            alt="Гаранция за качество и сигурност"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Капсулите Хепатофелин са произведени в заводите на Capsugel в Колмар
            - Франция, по патентованата технология за влагане на течни
            растителни екстракти в твърди капсули.
          </p>
          <p>
            При тази технология се елиминира термичната обработка на веществата,
            което позволява да се запазят полезните им свойства и гарантира
            максимално бързо и пълно усвояване от организма. В сравнение с
            твърдите форми, като таблетките, съставките, намиращи се в капсулите
            Хепатофелин се освобождават много бързо, защото са предварително
            разтворени. Те са напълно натурални и са свободни от разтворители,
            консерванти, глутен, захар и ГМО.
          </p>
          <p>
            Прочетете повече в раздел <Link to="/technology">“Технология”</Link>
          </p>
        </Col>
      </Row>

      <Row className="product-container mb-2">
        <Col>
          <h5 className="section-heading">
            Мнения на специалисти
            <br />и потребители
          </h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Carousel
            indicators={false}
            fade={false}
            interval={4500}
            className="shadow-1 mx-auto testimonial-container"
          >
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial1}
                  alt="Мнение на потребител"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">
                      Хепатофелин спаси черния ми дроб
                    </h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    Здравейте! Пиша, за да изкажа своето възхищение от продукта
                    Ви Хепатофелин. Дълги години приемам лекарства, поради
                    здравословен проблем. Наддадох с 10 кг, заради което бях на
                    постоянни диети и пиех какви ли не хапчета. В следствие на
                    всичко това, корема ми започна да се подува. Имах болка в
                    дясното подребрие като след време се появи упорито гадене и
                    повръщане, постоянна отпадналост и др. Лежах в болница,
                    където ме лекуваха с хепатопротектори, но чернодробните ми
                    показатели си оставаха високи. Минавах от един продукт на
                    друг... и нищо, болката оставаше, както и дискомфорта.
                    Постоянно бях изморена. Работя като ресурсен учител и
                    пътувам много, не се храня редовно и предполагам че и това е
                    пречка за бързото ми възстановяване. Един ден случайно в
                    интернет видях за Хепатофелин и реших, че нямам какво да
                    губя. Започнах да го пия и не след дълго усетих подобрение.
                    Отока на стомаха взе да спада, състоянието ми се подобри,
                    имах енергия и болката в подребрието изчезна. Хепатофелин за
                    мен се оказа спасение, препоръчвам го на всеки с чернодробни
                    проблеми.
                    <br />
                    <br />
                    Анастасия Атанасова, гр. Попово
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial2}
                  alt="Мнение на потребител"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">Алкохолът убива черния дроб</h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    Мъжът ми от доста години злоупотребяваше с алкохола. Не бяха
                    само приятелските сбирки, нито празниците вкъщи. Напиването
                    ставаше системно. Близки и приятели непрекъснато го
                    съветвахме, че това няма да излезе с добър край, но нямаше
                    кой да слуша. Все пак успях някак си да го убедя да отидем
                    на лекар, след като започна да се оплаква от болки в дясната
                    страна. Разбира се, докторът не остана доволен от
                    резултатите му: чернодробните клетки бяха започнали да
                    затлъстяват от алкохола, както и да се покачват нивата на
                    чернодробните ензими. На всяка цена трябваше да се вземат
                    мерки, както и да се спре с пиенето. Помислихме си, че
                    докторът ще ни изпише куп лекарства, които допълнително ще
                    натоварят черния му дроб. Предписа му Хепатофелин и каза да
                    спре с пиенето. След 2 месеца на контролния преглед,
                    докторът показа, че нивата на ензимите са започнали да се
                    нормализират и като продължим с Хепатофелин и диета, нещата
                    ще си дойдат на място. Хепатофелин ни върна радостта и
                    надеждата, защото се страхувах от най-лошото &ndash; всеки
                    знае, че алкохолът убива.
                    <br />
                    <br />
                    Ангелина Иванова на 56 г. от Варна
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial3}
                  alt="Мнение на потребител"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">За здрав черен дроб</h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    Казвам се Кольо Здравков, пенсионер съм вече, но дълги
                    години заемах висок пост в държавно предприятие. Работата ми
                    изискваше много срещи, командировки, които най-добре се
                    уреждаха на маса. Харесвах работатa и начина си на живот.
                    <br />
                    <br />
                    Дългите години на гуляи с хапване и пийване ми докараха
                    проблеми с черния дроб. Започна да ме боли, лекарите първо
                    казаха, че е увеличен, но с диета и лечение ще се оправи.
                    Стигнах до стеатоза която е доста упорита за лечение. Взимах
                    предписаните ми лекарства, но подобрението идваше бавно.
                    Аптекарят в нашата аптека ми препоръча едновремено с
                    медикаментите да пия и Хепатофелин за по-бързо
                    възстановяване на черния дроб. Доверих му се. Това се оказа
                    най-добрия избор за мен. Само за 4 месеца изследванията ми
                    показаха че има подобрение, което не можех да постигна за
                    близо година.
                    <br />
                    Хепатофелин може да върши чудеса.
                    <br />
                    <br />
                    Кольо Здравков
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Повече за Хепатофелин</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Състав
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Бял трън</strong> – сух екстракт от семена, 800 mg/g
                    силимарин <br />
                    (Silybum marianum – extr. of seed, 800 mg/total silymarin)
                    100 mg
                  </p>
                  <p>
                    <strong>Артишок</strong> – сух екстракт от надземна част
                    (Cynara scolymus L. – extract of overground parts) 40 mg
                  </p>
                  <p>
                    <strong>Глухарче</strong> – сух екстракт от корени
                    (Taraxacum officinale – extr. of roots) 30.0 mg
                  </p>
                  <p>
                    <strong>Ленено масло</strong> (Linseed virgin oil) 300.0 mg
                  </p>
                  <p>
                    <strong>Пречистено соево масло</strong> (Soybean oil,
                    refined) 24 mg
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Прием
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Хепатофелин се приема по 2 капсули дневно след храна, не
                    по-малко от 3 месеца без прекъсване.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Противопоказания
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    Не са установени. Хепатофелин е хранителна добавка и не
                    предизвиква дразнене и свръхчувствителност, и може да се
                    приема продължително време. Хепатофелин може да се приема
                    самостоятелно или в комбинация с предписани от лекуващия
                    лекар медикаменти.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Действие
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Хепатофелин съдържа оптимална доза силимарин, за който има
                    научно доказан ефект върху чернодробното здраве:
                  </p>
                  <p>
                    Профилактика и лечение на хронични хепатити и чернодробни
                    възпаления: Няколко различни изследвания показват, че
                    оралният прием на силимарин въздейства благоприятно върху
                    тестовете на чернодробните ензими при различни видове
                    хепатит (хронични вирусни или причинени от алкохол).
                    Профилактика и лечение на Чернодробна цироза, Алкохолна
                    интоксикация: Европейски проучвания, направени при лечението
                    на цироза при орален прием на силимарин показват добри
                    резултати при много от изследваните пациенти, някои от които
                    са приемали добавката в продължение на пет години. Щетите от
                    алкохолна интоксикация, чийто симптом е махмурлукът, се
                    повлияват добре при голяма част от пациентите провели
                    терапия със силимарин в рамките на няколко седмици.
                    Чернодробна детоксикация и регенерация: Има доказателства,
                    че активните флавоноиди в силимарина подпомагат
                    чернодробните клетки в борбата им с срещу различни токсини.
                    Събрани са данни и за подобрение на чернодробните функции в
                    следствие прием на силимарин у пациенти, прекарали лечения с
                    различни увреждащи черния дроб медикаменти.
                  </p>
                  <p>Как действат активните съставки в Хепатофелин?</p>
                  <p>
                    БЕЛИЯТ ТРЪН е един от най-ефективните природни продукти за
                    подобряване функцията на черния дроб и поддържане дейността
                    на чернодробните клетки. Прилага се за укрепване на
                    чернодробната дейност при състояния, предизвикани от
                    токсични въздействия на алкохол, лекарства, хепатит, отрови
                    и др. Подпомага дейността на хепатоцитите (клетките на
                    черния дроб) и повишава тяхната устойчивост. Подкрепя
                    храносмилателната дейност като съдейства за образуването и
                    отделянето на жлъчка, подпомага обмяната на веществата.
                    Най-ценната съставка в екстракта от бял трън е флавоноида
                    силимарин. Положителното въздействие на силимарина върху
                    чернодробните клетки е резултат от конкурентно
                    взаимодействие с рецепторите за съответните токсини в
                    хепатоцитната мембрана. Силимаринът се свързва със
                    свободните радикали и намалява неблагоприятното влияние на
                    токсични вещества като алкохол, някои лекарства и др.
                    Подкрепя регенеративните функции на чернодробните клетки
                    като стимулира синтеза на протеини и фосфолипиди в
                    хепатоцитите.
                  </p>
                  <p>
                    AРТИШОКЪТ, със съдържащите се в него биоактивни вещества,
                    подпомага функциите на клетките на черния дроб, особено при
                    въздействие на различни токсини. АРТИШОКЪТ е особено богат
                    на цинарин - биофлавониоид, мощен антиоксидант, който
                    подкрепя детоксикиращите функции на черния дроб и на
                    отделителната система Артишокът засилва жлъчната секреция и
                    диурезата, стимулира метаболизма на холестерола.
                  </p>
                  <p>
                    ГЛУХАРЧЕТО съдържа биологично активни вещества, които
                    подпомагат секрецията и тонуса на храносмилателната система
                    и жлъчната секреция. Прилага се за подкрепа дейността на
                    черния дроб, на диуретичната дейност на бъбреците и
                    пикочните пътища и на стомашно - чревния тракт. Повишава
                    защитните функции на организма.
                  </p>
                  <p>
                    СОЕВОТО МАСЛО е известно с богатото си съдържание на лецитин
                    и фосфолипиди, които спомагат метаболизирането на мазнините
                    в кръвта, регулират нивата на серумния холестерол и
                    подпомагат дейността на стените на кръвоносните съдове.
                  </p>
                  <p>
                    ЛЕНЕНОТО МАСЛО е богато на дълговерижни полиненаситени
                    мастни киселини: омега-3, омега-6 и омега-9. Приемът на
                    ленено масло подкрепя дейността на сърдечно- съдовата
                    система, съдейства за поддържане здравословни нивата на
                    кръвната захар.
                  </p>
                  <p>Причините за повишени чернодробни ензими</p>
                  <p>
                    Черният дроб е орган, който играе важна роля в множество
                    физиологични процеси в организма. Има много фактори, които
                    застрашават правилното му функциониране. “Сигнали” за нещо
                    нередно е повишаването на стойностите на чернодробните
                    ензими, които могат да варират от лека инфекция до рак.
                  </p>
                  <p>
                    Черния дроб е най-големият орган в човешкото тяло. Той помага
                    при храносмилането, метаболизма, синтеза на протеини и
                    цялостна детоксикация. Но чернодробните ензими могат да
                    бъдат повлияни от редица условия в други различни органи.
                    Промените в нивата на чернодробните ензими могат да се
                    дължат и на проблеми с жлъчката, както и по-сериозни причини
                    като рак, цироза или сърдечна недостатъчност.
                  </p>
                  <p>Видове ензими</p>
                  <p>
                    Това са видовете ензими които биха могли да се повишат,
                    заедно с условия, които могат да повлияят върху нивата им в
                    лабораторни тестове.
                  </p>
                  <p>
                    АЛАТ (аланин-аминотрансфераза): Когато чернодробните клетки
                    са повредени поради някаква причина, нивото на ензима сe
                    повишава в кръвта. Ако няма никакви травма, причината за
                    високи нива, може да е инфекция, като вирусен хепатит или
                    приемане на прекалено голяма доза лекарства.
                  </p>
                  <p>
                    АСАТ (аспартат-аминотрансфераза): Този ензим също се
                    повишава при случаи на чернодробни щети. Въпреки това, той
                    не е специфичен само към черния дроб, и може да бъде с
                    повишени нива при проблем с бъбреците, мозъка, сърцето или
                    скелетни мускули. Ако АСАТ са по-високи от нормално, а АЛАТ
                    са в стойности, тогава проблема най-вероятно не е в черния
                    дроб.
                  </p>
                  <p>
                    АФ (алкална фосфатаза): Висока АФ е нормална при деца и
                    бременни жени. При други пациенти, може да показва проблем с
                    жлъчката, възпаление, цироза, хепатит или др. Също така
                    някой лекарства могат да повишат нивата на АФ в кръвта.
                  </p>
                  <p>
                    ГГТ (гама-глутамил трансфераза): Повишени нива могат да
                    означават хепатит, цироза или жлъчен проблем. Също би могъл
                    да бъде повишен от сърдечна недостатъчност, злоупотреба с
                    алкохол, както и някои лекарства.
                  </p>
                  <p>
                    ОБ (общ билирубин) и ДБ (директен билирубин): Високи нива на
                    билирубин могат да причинят жълтеница. Когато общия
                    билирубин е повишен, директния билирубин може да помогне за
                    разпознаване на проблема. Ако директния е нормален,
                    причината може да бъде цироза или хепатит. А в случай, че и
                    общия и директния са повишени, най-вероятно е жлъчен
                    проблем.
                  </p>
                  <p>
                    <a
                      href="https://vitaherb.bg/leaflets/Hepatofelin_Listovka.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Листовка на продукта
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://vitaherb.bg/leaflets/Hepatofelin-Quality-Certificate.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Сертификат за качество
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default HepatofelinPage
